import React from "react";
import "./style.scss";

const ConsentCheckbox = () => {
    return (
        <div id="consent-checkbox">
            <input
                type="checkbox"
                id="consent"
                required
            />
            <label>
                I agree to the&nbsp;<a href="https://app.glasshive.com/Account/PrivacyPolicy" target="_blank">Privacy Policy</a>.
            </label>
        </div>
    );
};

export default ConsentCheckbox;