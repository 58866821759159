import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { Container, Row, Col } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import ReviewCard from '../../components/ReviewCard';
import Loader from '../../components/Loader';
import './style.scss';
import ConsentCheckbox from '../../components/ConsentCheckbox';

const IndexPage = () => {
    useEffect(() => {});

    return (
        <Layout navTheme="light">
            <Seo title="Agencies" />
            <div className="agencies-page">
                <Container className="purple-gradient-background container-1">
                    <img
                        className="gear gear-1"
                        src={require('../../images/agencies/6s-settings.png')}
                        alt="gear"
                    />
                    <Row>
                        <Col className="centered-text max-width-900">
                            <h1>Power-up Your IT Marketing Agency</h1>
                            <p className="hero-subtext white-text">
                                Your complete sales & marketing solution
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <img
                            className="gear gear-2"
                            src={require('../../images/agencies/6s-settings.png')}
                            alt="gear"
                        />
                        <div className="hero-img-container">
                            <img
                                className="mobile-img"
                                src={require('../../images/agencies/mobile-hero.png')}
                                alt="agency management, data, and analytics"
                            />
                            <img
                                className="img-1"
                                src={require('../../images/agencies/Group 6813.png')}
                                alt="journey steps"
                            />
                            <img
                                className="img-2"
                                src={require('../../images/agencies/Component 103.png')}
                                alt="campaigns"
                            />
                            <img
                                className="img-3"
                                src={require('../../images/agencies/Group 6457.png')}
                                alt="marketing calendar"
                            />
                            <img
                                className="img-4"
                                src={require('../../images/agencies/Group 6771.png')}
                                alt="blurry total leads red background"
                            />
                            <img
                                className="img-5"
                                src={require('../../images/agencies/Group 6774.png')}
                                alt="blurry total meetings orange background"
                            />
                            <img
                                className="img-6"
                                src={require('../../images/agencies/Group 6772.png')}
                                alt="blurry total opportunities blue background"
                            />
                            <img
                                className="img-7"
                                src={require('../../images/agencies/Group 6773.png')}
                                alt="total wins green gradient"
                            />
                            <img
                                className="img-8"
                                src={require('../../images/agencies/Group 6810.png')}
                                alt="partners information"
                            />
                            <img
                                className="img-9"
                                src={require('../../svgs/agencies/color-lines.svg')}
                                alt="analytics graph"
                            />
                        </div>
                    </Row>
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('.hero-img-container')
                                .classList.add('animate');
                        }}
                    />
                </Container>
                <Container className="grey-swish-background container-2 ">
                    <Row className="">
                        <Col className=" max-width-900 centered-text">
                            <h3 className="purple-text big-h3">
                                Industry-leading marketing at scale
                            </h3>
                            <p className="wrapLineSib">
                                Digital marketing can change at the drop of a
                                hat, and the only way to stay on top of market
                                shifts is with great analytics and expert
                                partner management.
                            </p>
                            <p className="wrapLineSib">
                                GlassHive gives your agency a platform to carry
                                out truly influential digital marketing. With
                                complete visibility, management, data, and
                                analytics, you can become the powerhouse you
                                were meant to be.
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="grey-blob"
                        src={require('../../svgs/agencies/grey-oval.svg')}
                        alt="gradient grey blob"
                    />
                </Container>
                <Container className="container-3">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#client-management-video')
                                .play();
                        }}
                    />
                    <Row className="client-management-row">
                        <Col className="one-half centered-text rel width-55-perc">
                            <video
                                src={require('../../videos/client-management.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="client-management-video hide-on-mobile"
                                id="client-management-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile transform-scale-1x25"
                                src={require('../../images/agencies/Lappy.png')}
                                alt="sales dashboard"
                            />
                        </Col>
                        <Col className="one-half max-width-480 margin-top-75-neg z-index-999">
                            <h3 className="purple-text centered-mobile">
                                Client management made simple
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive allows you to see the complete picture
                                of the sales and marketing activities for every
                                one of your clients.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Whether the actions were carried out by your
                                team or your client — even if done on another
                                platform — you can see those activities within
                                GlassHive at any time.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="orange-swish-background container-4 ">
                    <Row className="flexed-mobile">
                        <Col className="one-half max-width-480 white-text">
                            <h3 className="centered-mobile">
                                Share all your marketing content with unlimited
                                scale
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive has the ability to build marketing
                                content in a dynamic fashion. Everything you
                                create in our content builders can be
                                auto-published to clients at any scale you
                                choose.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Our engine can also auto brand all those assets
                                with their design preferences and contact
                                information, enabling them to get to market
                                instantly.
                            </p>
                        </Col>
                        <Col className="one-half centered-text rel second-col width-55-perc">
                            <div className="slide-in-spider">
                                <img
                                    className="tablet-img"
                                    src={require('../../images/agencies/Group 7208.png')}
                                    alt="share marketing content"
                                />
                                <img
                                    className="white-lines img-1"
                                    src={require('../../images/agencies/Group 7209.png')}
                                    alt="white lines connecting marketing content"
                                />
                                <img
                                    className="img-2"
                                    src={require('../../images/agencies/Compass_Whitepaper.png')}
                                    alt="marketing collateral"
                                />
                                <img
                                    className="img-3"
                                    src={require('../../images/agencies/Computex_PPT.png')}
                                    alt="marketing collateral"
                                />
                                <img
                                    className="img-4"
                                    src={require('../../images/agencies/Easetech_Brochure.png')}
                                    alt="marketing collateral"
                                />
                                <img
                                    className="img-5"
                                    src={require('../../images/agencies/KJ_Brochure.png')}
                                    alt="marketing collateral"
                                />
                                <img
                                    className="img-6"
                                    src={require('../../images/agencies/Website_KJ.png')}
                                    alt="marketing collateral"
                                />
                                <img
                                    className="img-7"
                                    src={require('../../images/agencies/Library.png')}
                                    alt="published marketing collateral to partners"
                                />
                                <img
                                    className="img-8"
                                    src={require('../../images/agencies/Company_Brochure.png')}
                                    alt="marketing collateral"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('.slide-in-spider')
                                .classList.add('animate');
                        }}
                    />
                </Container>
                <Container className="container-5 ">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#track-everyone-video')
                                .play();
                        }}
                    />
                    <Row className="track-everyone-row">
                        <Col className="one-half centered-text rel  width-55-perc z-index-9 rel">
                            <video
                                src={require('../../videos/track-everyone.mp4')}
                                muted
                                playsInline
                                className="track-everyone-video hide-on-mobile"
                                id="track-everyone-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile .transform-scale-1x25"
                                src={require('../../images/agencies/Group 6837.png')}
                                alt="company,campaigns,opportunities linked to contact"
                            />
                        </Col>
                        <Col className="one-half max-width-480">
                            <h3 className="purple-text centered-mobile">
                                Track everyone and everything
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Your team does their best work when they have
                                real-time, deep data to work from.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Visibility is a central pillar of GlassHive. The
                                core functions, as well as the look and feel,
                                were created to enable intuitive visibility at a
                                glance with the ability to easily dive into
                                deeper, specific data.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-6 ">
                    <Row className="flexed-mobile">
                        <Col className="centered-text max-width-700">
                            <h3 className="purple-text">Automate & improve</h3>
                            <p className="wrapLineSib">
                                Many industries have been reaping the benefits
                                of automation for some time now . We figured it
                                was time sales and marketing could do the same.
                            </p>
                            <p className="wrapLineSib">
                                GlassHive has multiple tools that allow you to
                                automate for easier management and increased
                                success. Whether it’s automated email journeys,
                                campaign A/B testing, or a marketing plan,
                                GlassHive allows your agency to get more out of
                                your workday.
                            </p>
                        </Col>
                    </Row>
                    <div className="full-width-images flexed-order-1">
                        <img
                            className="mobile-med"
                            src={require('../../images/agencies/Group 6830.png')}
                            alt="journey,marketing plan template, and a/b testing campaign results"
                        />
                        <img
                            className="img-1"
                            src={require('../../images/agencies/Group 6813.png')}
                            alt="journey steps"
                        />
                        <img
                            className="img-2 gear"
                            src={require('../../images/agencies/6s-grey-settings.png')}
                            alt="gear"
                        />
                        <img
                            className="img-3"
                            src={require('../../images/agencies/Group 6457.png')}
                            alt="marketing plan template calendar"
                        />
                        <img
                            className="img-4 gear"
                            src={require('../../images/agencies/6s-grey-settings.png')}
                            alt="gear"
                        />
                        <img
                            className="img-5"
                            src={require('../../images/agencies/Group 6828.png')}
                            alt="a/b testing comparison"
                        />
                    </div>
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('.full-width-images')
                                .classList.add('animate');
                        }}
                    />
                </Container>
                <Container className="container-7">
                    <Row className="flexed-mobile marketing-plans-row">
                        <Col className="one-half max-width-480 mobile-margin-top-0">
                            <h3 className="purple-text centered-mobile">
                                Create & activate marketing plans
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Wouldn’t it be amazing if those detailed
                                marketing plans you created could instantly be
                                put to use and acted on?
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Marketing plans in GlassHive allow you to
                                create, schedule, and automatically send all of
                                your campaigns.
                            </p>
                        </Col>
                        <Col className="one-half centered-text rel width-55-perc z-index-9 rel">
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelector('#marketing-plans-video')
                                        .play();
                                }}
                            />
                            <video
                                src={require('../../videos/marketing-plans.mp4')}
                                muted
                                playsInline
                                className="marketing-plans-video hide-on-mobile"
                                id="marketing-plans-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile"
                                src={require('../../images/agencies/Group 6468.png')}
                                alt="marketing plan template calendar"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-8">
                    <Waypoint
                        onEnter={() => {
                            document.querySelector('#library').play();
                        }}
                    />
                    <Row className="flexed-mobile">
                        <Col className="centered-text max-width-700">
                            <h3 className="purple-text">
                                Package & resell your marketing
                            </h3>
                            <p className="wrapLineSib">
                                Have some emails, collateral, journeys, or
                                entire marketing plans that you want to sell to
                                future clients?
                            </p>
                            <p className="wrapLineSib">
                                GlassHive allows you to package up any of your
                                past marketing so it can be reused for future
                                clients. It even automatically rebrands with the
                                right colors, logos and links.
                            </p>
                        </Col>
                    </Row>
                    <Row className="package-resell-marketing-row full-row">
                        <img
                            className="odd-img-1"
                            src={require('../../svgs/agencies/4-blocks.svg')}
                            alt="campaigns"
                        />
                        <video
                            src={require('../../videos/package-resell-marketing.mp4')}
                            muted
                            playsInline
                            autoPlay
                            loop
                            className="hide-on-mobile package-resell-marketing-video"
                            id="library"
                        >
                            <p className="wrapLineSib">
                                If you are reading this, it is because your
                                browser does not support the HTML5 video
                                element.
                            </p>
                        </video>
                        <img
                            className="show-on-mobile odd-img-2"
                            src={require('../../svgs/agencies/squigles.svg')}
                            alt="purple and pink squiggly lines"
                        />
                    </Row>
                </Container>
                <Container className="container-9 ">
                    <Row className="flexed-mobile">
                        <Col className="centered-text ">
                            <h3 className="purple-text">
                                Agency-wide performance at a glance
                            </h3>
                            <p className="max-width-650 margin-auto wrapLineSib">
                                Want to see who your top-performing client is?
                                It’s right there. What about your average open
                                and click-through rates? That’s there too.
                            </p>
                            <p className="max-width-650 margin-auto wrapLineSib">
                                There is even an overview of total leads,
                                meetings, opportunities and wins to keep your
                                team aware of the importance and success of the
                                work they do.
                            </p>
                        </Col>
                    </Row>
                    <div className="second-full-width-images">
                        <img
                            className="mobile-img"
                            src={require('../../images/agencies/Group 7068.png')}
                            alt="agency analytics"
                        />
                        <img
                            className="img-1"
                            src={require('../../images/agencies/Group 6802.png')}
                            alt="partners summary"
                        />
                        <img
                            className="img-2"
                            src={require('../../images/agencies/Group 6751.png')}
                            alt="campaign and wedsite analytics"
                        />
                        <img
                            className="img-3"
                            src={require('../../images/agencies/Group 466.png')}
                            alt="total leads in red gradient background"
                        />
                        <img
                            className="img-4"
                            src={require('../../images/agencies/Group 467.png')}
                            alt="total opportunities in blue gradient background"
                        />
                        <img
                            className="img-5"
                            src={require('../../images/agencies/Group 469.png')}
                            alt="total wins in green gradient background"
                        />
                        <img
                            className="img-6"
                            src={require('../../images/agencies/Group 468.png')}
                            alt="transparent total meetings"
                        />
                    </div>
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('.second-full-width-images')
                                .classList.add('animate');
                        }}
                    />
                </Container>
                <Container className="container-10">
                    <Row>
                        <div className="logos-container deep-purple-background centered-text">
                            <h3 className="white-text">
                                Join the channel partner success revolution
                            </h3>
                            <div className="logo-images">
                                <Waypoint
                                    onEnter={() => {
                                        document
                                            .querySelector('.logo-images')
                                            .classList.add('animate');
                                    }}
                                />
                                <div className="one-third first">
                                    <img
                                        src={require('../../images/agencies/Group 7071.png')}
                                        alt="tridigital logo"
                                    />
                                </div>
                                <div className="one-third second">
                                    <img
                                        src={require('../../images/agencies/pronto-marketing.png')}
                                        alt="pronto logo"
                                    />
                                </div>
                                <div className="one-third third">
                                    <img
                                        src={require('../../images/agencies/Group 7070.png')}
                                        alt="kpi captain logo"
                                    />
                                </div>
                                <div className="one-third fourth">
                                    <img
                                        src={require('../../images/agencies/Group 7069.png')}
                                        alt="kokua logo"
                                    />
                                </div>
                                <div className="one-third fifth">
                                    <img
                                        src={require('../../images/agencies/spclogo.png')}
                                        alt="spc logo"
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="container-11 overflow-hidden">
                    <Row className="flexed-mobile stretch">
                        <Col className="one-half small-width review-row">
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelectorAll(
                                            '.review-row .review-card'
                                        )
                                        .forEach(card => {
                                            card.classList.add('slide-up');
                                        });
                                }}
                            />

                            <ReviewCard
                                id="p4b1av1y5c"
                                thumbnailUrl={require('../../images/agencies/blue-river.jpg?image_crop_resized=640x358')}
                                clientCompany="Blue River Tech Partners"
                                clientName="Joseph Pena"
                                description="It gives you a ton of information that I didn't even know you could get."
                            />
                        </Col>
                        <Col className="one-half centered-text rel z-index-9 small-width min-height-100">
                            <form
                                id="agencies-form"
                                className="pink-form"
                                method="POST"
                                name="Agencies Form"
                                action="/agencies/#thanks"
                                data-netlify="true"
                                data-netlify-honeypot="bot-field"
                                data-category="Agency"
                                encType="multipart/form-data"
                                onSubmit={e => {
                                    e.preventDefault();
                                    const submitButton = document.getElementById(
                                        'submit-btn'
                                    );
                                    const loader = document.querySelector(
                                        '.loader'
                                    );
                                    const agencyForm = document.getElementById(
                                        'agencies-form'
                                    );

                                    loader.style.display = 'block';
                                    submitButton.style.display = 'none';

                                    fetch(agencyForm.getAttribute('action'), {
                                        method: 'POST',
                                        body: new FormData(
                                            document.getElementById(
                                                'agencies-form'
                                            )
                                        ),
                                    }).then(response => {
                                        if (response.status === 200) {
                                            document.querySelector(
                                                '#agencies-form'
                                            ).style.display = 'none';
                                            document.querySelector(
                                                '.contact-thank-you'
                                            ).style.display = 'block';
                                        } else {
                                            loader.style.display = 'none';
                                            document.getElementById(
                                                'error-msg'
                                            ).style.display = 'block';
                                            submitButton.style.display =
                                                'block';
                                        }
                                    });
                                }}
                            >
                                <input
                                    type="hidden"
                                    name="form-name"
                                    value="agencies-form"
                                />
                                <input
                                    type="hidden"
                                    name="bot-field"
                                    id="bot"
                                />
                                <h3 className="white-text form-title">
                                    Request a demo of GlassHive’s amazing agency
                                    features
                                </h3>
                                <div className="what-do-we-call-you">
                                    <div className="field name-field">
                                        <label htmlFor="firstName" hidden>
                                            What’s your name?
                                        </label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            placeholder="Name"
                                            required
                                        ></input>
                                    </div>
                                    <div className="field email-field">
                                        <label hidden htmlFor="email">
                                            What’s your email address?
                                        </label>
                                        <input
                                            id="email"
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            required
                                        ></input>
                                    </div>
                                    <div className="field">
                                        <label
                                            className="second-label"
                                            hidden
                                            htmlFor="company"
                                        >
                                            What company are you with?
                                        </label>
                                        <input
                                            id="company"
                                            type="text"
                                            name="company"
                                            placeholder="Company"
                                        />
                                    </div>
                                </div>

                                <div className="field comment-field">
                                    <label
                                        hidden
                                        className="textarea-label"
                                        htmlFor="message"
                                    >
                                        What question can we answer for you?
                                    </label>
                                    <textarea
                                        id="message"
                                        type="text"
                                        name="message"
                                        placeholder="Comments"
                                    ></textarea>
                                </div>
                                <div className="hide-me field">
                                    <input
                                        data-form-type="Consultation"
                                        className="formcat"
                                        name="formType"
                                    />
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <ConsentCheckbox />
                                </div>
                                <div className="submit-btn">
                                    <Loader />
                                    <p id="error-msg">
                                        Looks like there was a problem
                                        submitting your form. Please ensure ALL
                                        form fields are filled out and try
                                        again.
                                    </p>
                                    <button
                                        id="submit-btn"
                                        type="submit"
                                        className="pink-button"
                                    >
                                        SEND
                                    </button>
                                </div>
                            </form>
                            <div
                                className="contact-thank-you reach"
                                id="thanks"
                            >
                                <h5>
                                    Thank you for contacting us. We'll be in
                                    touch shortly!
                                </h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default IndexPage;
